const questions = [
  {
    questionKey: 1,
    text: "食事を食べる時間はいつも決まっていますか？",
    type: "radio",
    questionItem: [
      { key: 1, text: "はい" },
      { key: 2, text: "いいえ" },
    ],
  },
  {
    questionKey: 2,
    text: "朝食をほぼ毎日とりますか？",
    type: "radio",
    questionItem: [
      { key: 1, text: "はい" },
      { key: 2, text: "いいえ" },
    ],
  },
  {
    questionKey: 3,
    text: "寝る前2時間以内に夕食、夜食（甘い食べ物、飲み物含む）を食べないようにしていますか？",
    type: "radio",
    questionItem: [
      { key: 1, text: "はい" },
      { key: 2, text: "いいえ" },
    ],
  },
  {
    questionKey: 4,
    text: "⾷事はよく噛んでゆっくり食べるようにしていますか？",
    type: "radio",
    questionItem: [
      { key: 1, text: "はい" },
      { key: 2, text: "いいえ" },
    ],
  },
  {
    questionKey: 5,
    text: "食事のバランス（ごはん・麺などの主食、肉・魚などの主菜、サラダ・お浸しなどの野菜を使った副菜を揃える）を考えて食べていますか？",
    type: "radio",
    questionItem: [
      { key: 1, text: "はい" },
      { key: 2, text: "いいえ" },
    ],
  },
  {
    questionKey: 6,
    text: "糖分の入った飲み物（ジュース、砂糖入りのコーヒー、スポーツドリンクなど）を定期的に飲みますか？",
    type: "radio",
    questionItem: [
      { key: 1, text: "はい" },
      { key: 2, text: "いいえ" },
    ],
  },
  {
    questionKey: 7,
    text: "習慣的に間食（おやつ）を食べますか？",
    type: "radio",
    questionItem: [
      { key: 1, text: "はい" },
      { key: 2, text: "いいえ" },
    ],
  },
  {
    questionKey: 8,
    text: "塩分の多い食材（麺類・漬物・佃煮・梅干し・練り製品）や濃い味付けのものを毎日食べていますか？",
    type: "radio",
    questionItem: [
      { key: 1, text: "はい" },
      { key: 2, text: "いいえ" },
    ],
  },
  {
    questionKey: 9,
    text: "外食、惣菜、市販の弁当を習慣的に食べますか？（習慣的に食べる項目を選択）(複数選択可)",
    type: "checkbox",
    questionItem: [
      { key: 1, text: "食べない" },
      { key: 2, text: "朝食" },
      { key: 3, text: "昼食" },
      { key: 4, text: "夕食" },
    ],
  },
  {
    questionKey: 10,
    text: "習慣的にお酒を飲みますか？",
    type: "radio",
    questionItem: [
      { key: 1, text: "毎日" },
      { key: 2, text: "週４〜５回" },
      { key: 3, text: "週２〜３回" },
      { key: 4, text: "週１回以下" },
      { key: 5, text: "飲まない" },
    ],
  },
  {
    questionKey: 11,
    text: "食事は主に誰が作りますか？",
    type: "radio",
    questionItem: [
      { key: 1, text: "自分" },
      { key: 2, text: "自分以外" },
      { key: 3, text: "決まっていない" },
    ],
  },
  {
    questionKey: 12,
    text: "これまでにダイエットやトレーニングで食生活を変えようとした経験はありますか？",
    type: "radio",
    questionItem: [
      { key: 1, text: "はい" },
      { key: 2, text: "いいえ" },
    ],
  },
  {
    questionKey: 13,
    text: "このサービスを受ける目的は何ですか？(複数選択可)",
    type: "checkbox",
    questionItem: [
      { key: 1, text: "ダイエット" },
      { key: 2, text: "老化防止、若々しさの維持" },
      { key: 3, text: "美容" },
      { key: 4, text: "体力の維持、向上" },
      { key: 5, text: "筋肉を効率よくつけたい" },
      {
        key: 6,
        text: "コンディショニング(疲労の回復、パフォーマンスの向上等)",
      },
      { key: 7, text: "病気の予防・改善（生活習慣病）" },
      { key: 8, text: "その他" },
    ],
  },
  {
    questionKey: 14,
    text: "体重を定期的に測定していますか？",
    type: "radio",
    questionItem: [
      { key: 1, text: "はい" },
      { key: 2, text: "いいえ" },
    ],
  },
  {
    questionKey: 15,
    text: "ここ1年間で体重の変動はありましたか？",
    type: "radio",
    questionItem: [
      { key: 1, text: "3kg以上増加" },
      { key: 2, text: "ほとんど変化なし" },
      { key: 3, text: "3kg以上減少" },
    ],
  },
  {
    questionKey: 16,
    text: "現状の体重について、どうしたいですか？",
    type: "radio",
    questionItem: [
      { key: 1, text: "できれば減らしたい" },
      { key: 2, text: "現状維持したい" },
      { key: 3, text: "できれば増やしたい" },
    ],
  },
  {
    questionKey: 17,
    text: "１日平均どのくらいの睡眠時間ですか？",
    type: "radio",
    questionItem: [
      { key: 1, text: "５時間未満" },
      { key: 2, text: "５〜６時間未満" },
      { key: 3, text: "６〜７時間未満" },
      { key: 4, text: "７〜８時間未満" },
      { key: 5, text: "８時間以上" },
    ],
  },
  {
    questionKey: 18,
    text: "お通じの状態はどうですか？",
    type: "radio",
    questionItem: [
      { key: 1, text: "1日に1回以上" },
      { key: 2, text: "2〜3日に1回程度" },
      { key: 3, text: "週1回以下" },
    ],
  },
  {
    questionKey: 19,
    text: "現在、運動や食事などの生活習慣について主治医より指導を受けていますか？",
    type: "radio",
    questionItem: [
      { key: 1, text: "はい" },
      { key: 2, text: "いいえ" },
    ],
  },
  // {
  //   questionKey: 20,
  //   text: "食生活を大きく変えたいと思いますか？",
  //   type: "radio",
  //   questionItem: [
  //     { key: 1, text: "はい" },
  //     { key: 2, text: "いいえ" },
  //   ],
  // },
  {
    questionKey: 20,
    text: "食生活を改善してみようと思いますか？",
    type: "radio",
    questionItem: [
      { key: 1, text: "食生活を改善することは考えていない" },
      {
        key: 2,
        text: "食生活を改善した方がいいとは思っているが、実際に食生活を変えていない",
      },
      {
        key: 3,
        text: "食生活をすぐに改善するつもりがある",
      },
      {
        key: 4,
        text: "既に改善に取り組んでいる（６ヶ月未満）",
      },
      {
        key: 5,
        text: "既に改善に取り組んでいる（６ヶ月以上）",
      },
    ],
  },
  {
    questionKey: 21,
    text: "食生活を変える場合、どのようなサポートを受けたいですか？",
    type: "radio",
    questionItem: [
      { key: 1, text: "早く結果につながるためのサポート" },
      {
        key: 2,
        text: "褒められたり、共感されたりすることが多いサポート",
      },
      {
        key: 3,
        text: "データや根拠を元にしたサポート",
      },
      {
        key: 4,
        text: "できていることや努力に目を向け励ましてもらえるようなサポート",
      },
    ],
  },
  {
    questionKey: 22,
    text: "現在治療中の疾患はありますか？(複数選択可)",
    type: "checkbox",
    questionItem: [
      { key: 1, text: "特になし" },
      { key: 2, text: "肥満症/メタボリックシンドローム" },
      { key: 3, text: "高血圧" },
      { key: 4, text: "脂質異常症（高脂血症）" },
      { key: 5, text: "糖尿病" },
      { key: 6, text: "CKD（慢性腎臓病）" },
      { key: 7, text: "心臓病・不整脈" },
      { key: 8, text: "高尿酸血症/痛風" },
      { key: 9, text: "動脈硬化" },
      { key: 10, text: "脂肪肝" },
      { key: 11, text: "その他" },
    ],
  },
  {
    questionKey: 23,
    text: "食物アレルギーで避けた方が良い食品はありますか？(複数選択可)",
    type: "checkbox",
    questionItem: [
      { key: 1, text: "特になし" },
      { key: 2, text: "えび" },
      { key: 3, text: "かに" },
      { key: 4, text: "小麦" },
      { key: 5, text: "そば" },
      { key: 6, text: "卵" },
      { key: 7, text: "乳成分" },
      { key: 8, text: "落花生" },
      { key: 9, text: "あわび" },
      { key: 10, text: "いか" },
      { key: 11, text: "いくら" },
      { key: 12, text: "オレンジ" },
      { key: 13, text: "カシューナッツ" },
      { key: 14, text: "キウイフルーツ" },
      { key: 15, text: "牛肉" },
      { key: 16, text: "くるみ" },
      { key: 17, text: "ごま" },
      { key: 18, text: "さけ" },
      { key: 19, text: "さば" },
      { key: 20, text: "大豆" },
      { key: 21, text: "鶏肉" },
      { key: 22, text: "バナナ" },
      { key: 23, text: "豚肉" },
      { key: 24, text: "まつたけ" },
      { key: 25, text: "もも" },
      { key: 26, text: "やまいも" },
      { key: 27, text: "りんご" },
      { key: 28, text: "ゼラチン" },
      { key: 29, text: "その他" },
    ],
  },
  {
    questionKey: 24,
    text: "自分の健康のために特に気を付けていることはありますか？(複数選択可)",
    type: "checkbox",
    questionItem: [
      { key: 1, text: "できる限り自炊するようにしている" },
      { key: 2, text: "塩分や脂質を摂り過ぎないように意識している" },
      { key: 3, text: "サプリメントを利用して栄養を補っている" },
      { key: 4, text: "甘いものを控えるようにしている" },
      { key: 5, text: "タンパク質を多く摂るようにしている" },
      { key: 6, text: "野菜を多く食べるようにしている" },
      { key: 7, text: "定期的に体重計もしくは体組成計を測っている" },
      { key: 8, text: "健康情報の科学的根拠を重視している" },
      { key: 9, text: "その他" },
      { key: 10, text: "特になし" },
    ],
  },
];

export default questions;
