<template>
  <div class="user-information-wrapper">
    <div class="button-container">
      <button class="yellow btn" @click="openUpdateUserInformationModal">ユーザー情報修正</button>
      <!-- <button class="red btn" @click="deleteUser">
                ユーザー削除
            </button> -->
    </div>
    <div class="user-information-container">
      <div class="left-user-information-container">
        <div class="user-information border-radius">
          <UserBasicInformation class="user-basic-information" />
        </div>
        <div class="eating-habit border-radius">
          <EatingHabit />
        </div>
      </div>
      <div class="right-user-information-container">
        <div class="weight-graph border-radius">
          <WeightChart />
        </div>
        <div class="body-fat-graph border-radius">
          <BodyFatChart />
        </div>
        <div class="muscle-graph border-radius">
          <MuscleChart />
        </div>
      </div>
    </div>
    <!-- モーダルウィンドウ -->
    <div class="overlay" v-show="this.$store.state.showUpdateUserInformationModal">
      <div class="record-modal border-radius">
        <button class="close-button pink btn" @click="closeUpdateUserInformationModal">閉じる</button>
        <UpdateUserInformationModal />
      </div>
    </div>
  </div>
</template>

<script>
import WeightChart from "./WeightChart";
import BodyFatChart from "./BodyFatChart";
import MuscleChart from "./MuscleChart";
import EatingHabit from "./EatingHabit";
import UserBasicInformation from "./UserBasicInformation";
import UserRegistration from "./UserRegistration";
import UpdateUserInformationModal from "./UpdateUserInformationModal";

export default {
  name: "UserInformation",
  components: {
    WeightChart,
    BodyFatChart,
    MuscleChart,
    EatingHabit,
    UserBasicInformation,
    UserRegistration,
    UpdateUserInformationModal,
  },
  data() {
    return {
      // id: this.$store.state.useData
    };
  },
  methods: {
    openUpdateUserInformationModal() {
      this.$store.commit("openUpdateUserInformationModal");
    },
    closeUpdateUserInformationModal() {
      this.$store.commit("closeUpdateUserInformationModal");
    },
    deleteUser() {
      const result = confirm("ユーザーを削除しますか？※こちらの操作は元に戻せません。");
      if (!result) return;
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.user-information-wrapper {
  /* background-color: #bbb; */
  padding: 0.5rem;
  height: calc(100vh - 60px - 39px);
  /* ヘッダーの高さ - タブの高さ */
  /* display: flex; */
}
.user-information-container {
  display: flex;
  height: 95%;
}
.left-user-information-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.right-user-information-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.right-user-information-container::-webkit-scrollbar {
  display: none;
}
.user-information {
  /* height: 50%; */
  background-color: #fafafa;
  /* overflow-y: auto; */
}
.user-information::-webkit-scrollbar {
  display: none;
}
.user-basic-information {
  height: 100%;
}
.eating-habit {
  /* height: 50%; */
  background-color: #fafafa;
  /* overflow-y: auto; */
  /* display: flex; */
}
.eating-habit::-webkit-scrollbar {
  display: none;
}
.weight-graph,
.body-fat-graph,
.muscle-graph {
  height: 33.3%;
  background-color: #fafafa;
  /* display: flex; */
}
.overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.record-modal {
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 2;
  padding: 1em;
  background: #fff;
  overflow-y: auto;
}
.record-modal::-webkit-scrollbar {
  display: none;
}
.close-button {
  margin-bottom: 1rem;
}
.button-container {
  height: 5%;
  display: flex;
  justify-content: flex-start;
}
.btn {
  margin-right: 0.5rem;
  margin-left: auto;
}
@media (max-height: 800px) {
  .weight-graph,
  .body-fat-graph,
  .muscle-graph {
    height: 50%;
  }
}
</style>
