<template>
  <div class="image-recording-container">
    <div class="button-container">
      <button class="add-image-button blue btn" @click="openAddImageModal">画像追加</button>
    </div>
    <div class="image-container">
      <div class="user-information border-radius">
        <div v-for="image in images" :key="image.createdAt.seconds" class="image-wrapper">
          <div class="popover-wrapper">
            <CDropdown toggler-text="" class="edit-dropdown" size="sm" variant="ghost">
              <CDropdownItem @click="edit(image)">編集</CDropdownItem>
              <CDropdownItem @click="deleteImage(image.imageId)">削除</CDropdownItem>
            </CDropdown>
          </div>
          <div class="image-outer" @click="openImageModal(image.image)">
            <img class="image" :src="`${image.image}`" alt="" />
          </div>
          <div class="post-date">{{ convertFromTimestampToDatetime(image.date.seconds) }}</div>
          <div class="comment">{{ image.comment }}</div>
        </div>
      </div>
    </div>

    <!-- モーダルウィンドウ -->
    <div class="overlay" v-show="showAddImageModal">
      <div class="record-modal border-radius">
        <div class="modal-button-container">
          <p>
            <button class="close-button pink btn" @click="closeAddImageModal">閉じる</button>
          </p>
          <div>
            <label class="upload-file-button blue btn"
              >写真を選択してください
              <input class="input-file" type="file" name="datafile" ref="preview" @change="uploadImageFile" />
            </label>
          </div>
        </div>
        <div class="upload-content">
          <div class="upload-file-container">
            <div class="delete-button-container" v-if="uploadFileUrl">
              <div class="delete-button" @click="deletePreview">×</div>
            </div>
            <img class="upload-file" v-if="uploadFileUrl" :src="uploadFileUrl" alt="" />
          </div>
          <div class="datepicker-container">
            <Datepicker class="datepicker" :language="ja" :inline="true" :format="DatePickerFormat" v-model="date" />
            <!-- <div>{{date}}</div> -->
            <div class="comment">
              <div>コメント</div>
              <textarea v-model="comment" class="textarea" name=""></textarea>
            </div>
            <button class="send-button green btn" @click="saveImage">登録</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 画像拡大モーダル -->
    <CModal centered size="lg" :show.sync="showEnlargeModal">
      <img class="image" :src="enlargeModalSrc" alt="" />
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
    <!-- 編集モーダル -->
    <CModal centered size="lg" :show.sync="showEditModal">
      <div class="edit-modal">
        <img class="edit-image" :src="enlargeModalSrc" alt="" />
        <div class="datepicker-container">
          <Datepicker class="datepicker" :language="ja" :inline="true" :format="DatePickerFormat" v-model="editDate" />
          <!-- <div>{{date}}</div> -->
          <div class="comment">
            <div>コメント</div>
            <textarea v-model="editComment" class="textarea" name=""></textarea>
          </div>
          <button class="send-button green btn" @click="updateImage(imageDocId)">更新</button>
        </div>
      </div>
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
  </div>
</template>

<script>
import firebase from "firebase";
import Datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";
import { v4 as uuidv4 } from "uuid";

const db = firebase.firestore();

export default {
  name: "ImageRecording",
  components: {
    Datepicker,
  },
  data() {
    return {
      id: this.$store.state.userData.id,
      showAddImageModal: false,
      fileName: "",
      uploadFileUrl: null,
      uploadFileUrl2: null,
      DatePickerFormat: "yyyy-MM-dd",
      ja,
      date: new Date(),
      comment: "",
      images: [],
      showEnlargeModal: false,
      showEditModal: false,
      enlargeModalSrc: null,
      editComment: null,
      editDate: new Date(),
      imageDocId: null,
      editDateValue: null,
    };
  },
  created() {
    this.fetchImages();
  },
  methods: {
    convertFromTimestampToDatetime(timestamp) {
      const _d = timestamp ? new Date(timestamp * 1000) : new Date();
      const Y = _d.getFullYear();
      const m = (_d.getMonth() + 1).toString().padStart(2, "0");
      const d = _d.getDate().toString().padStart(2, "0");
      const H = _d.getHours().toString().padStart(2, "0");
      const i = _d.getMinutes().toString().padStart(2, "0");
      const s = _d.getSeconds().toString().padStart(2, "0");
      return `${m}/${d}`;
      // return `${Y}/${m}/${d} ${H}:${i}:${s}`;
    },
    openAddImageModal() {
      this.showAddImageModal = true;
    },
    closeAddImageModal() {
      this.showAddImageModal = false;
    },
    uploadImageFile() {
      const file = this.$refs.preview.files[0];
      this.fileName = this.$refs.preview.files[0].name;
      this.uploadFileUrl = URL.createObjectURL(file);
      let reader = new FileReader();
      reader.readAsDataURL(file);
      this.uploadFileUrl2 = reader;

      this.$refs.preview.value = "";
    },
    deletePreview() {
      this.uploadFileUrl = "";
      URL.revokeObjectURL(this.uploadFileUrl);
    },
    async saveImage() {
      if (this.uploadFileUrl) {
        this.closeAddImageModal();
        const imageRecordingDocRef = await this.$store.dispatch("createImageRecordingDocRef");
        const ext = this.fileName.split(".").pop();
        const storagePath = `images/${imageRecordingDocRef.id}.${ext}`;
        const downloadUrl = await this.uploadImage(this.uploadFileUrl2.result, storagePath);
        db.collection("images").doc(this.id).collection("imageRecording").add({
          _id: uuidv4(),
          createdAt: new Date(),
          date: this.date,
          comment: this.comment,
          image: downloadUrl,
        });
        this.deletePreview();
      } else {
        alert("送信に失敗しました。");
      }
      this.comment = null;
    },
    async uploadImage(uri, path) {
      // uriをblobに変換
      const localUri = await fetch(uri);
      const blob = await localUri.blob();
      const ref = firebase.storage().ref().child(path);

      let downloadUrl = "";
      try {
        await ref.put(blob);
        downloadUrl = await ref.getDownloadURL();
      } catch (err) {
        // console.log(err);
      }
      return downloadUrl;
    },
    fetchImages() {
      db.collection("images")
        .doc(this.id)
        .collection("imageRecording")
        .orderBy("createdAt", "asc")
        .onSnapshot((querySnapshot) => {
          let allImages = [];
          querySnapshot.forEach((doc) => {
            if (!doc.data().deletedAt) {
              allImages.push({
                comment: doc.data().comment,
                // date: doc.data().date,
                date: doc.data().date,
                image: doc.data().image,
                imageId: doc.id,
                createdAt: doc.data().createdAt,
              });
            }
          });
          this.images = allImages;
        });
    },
    openImageModal(src) {
      this.enlargeModalSrc = src;
      this.showEnlargeModal = true;
    },
    edit(e) {
      console.log("e", e);
      this.editComment = e.comment;
      // e.createdAt.nanosecondsをnew Date形式に変換する関数
      const convertFromTimestampToDatetime = (timestamp) => {
        const _d = timestamp ? new Date(timestamp * 1000) : new Date();
        const Y = _d.getFullYear();
        const m = (_d.getMonth() + 1).toString().padStart(2, "0");
        const d = _d.getDate().toString().padStart(2, "0");
        const H = _d.getHours().toString().padStart(2, "0");
        const i = _d.getMinutes().toString().padStart(2, "0");
        const s = _d.getSeconds().toString().padStart(2, "0");
        return `${Y}/${m}/${d} ${H}:${i}:${s}`;
      };
      this.editDate = convertFromTimestampToDatetime(e.date.seconds);
      this.enlargeModalSrc = e.image;
      this.imageDocId = e.imageId;
      this.showEditModal = true;
    },
    async updateImage(imageDocId) {
      console.log("this.editDate", this.editDate);
      await db
        .collection("images")
        .doc(this.id)
        .collection("imageRecording")
        .doc(imageDocId)
        .update({
          updatedAt: new Date(),
          date: this.editDate,
          comment: this.editComment,
        })
        .catch((error) => {
          alert("更新に失敗しました。");
          console.error("Error updating document: ", error);
        });
      this.showEditModal = false;
    },
    async deleteImage(imageDocId) {
      if (!confirm("この作業は元に戻せません。画像を削除しますか？")) {
        return false;
      } else {
        // firestoreの画像を論理削除する
        await db
          .collection("images")
          .doc(this.id)
          .collection("imageRecording")
          .doc(imageDocId)
          .update({
            deletedAt: new Date(),
          })
          .catch((error) => {
            alert("削除に失敗しました。");
            console.error("Error updating document: ", error);
          });
      }
    },
    // selectedDate(e) {
    //   console.log("e", e);
    //   this.editDate = e;
    // },
  },
};
</script>

<style scoped>
.title {
  padding-left: 1rem;
  font-weight: bold;
  font-size: 1.2rem;
}
.image-recording-container {
  /* background-color: #bbb; */
  padding: 0.5rem;
  height: calc(100vh - 60px - 39px);
  /* ヘッダーの高さ - タブの高さ */
  /* display: flex; */
}

.button-container {
  height: 5%;
  display: flex;
  justify-content: flex-start;
}
.image-container {
  height: 95%;
  display: flex;
  flex-direction: column;
}
.add-image-button {
  margin-left: 0.5rem;
}

.user-information {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  background-color: #fafafa;
  overflow-y: auto;
}
.user-information::-webkit-scrollbar {
  display: none;
}
.image-wrapper {
  height: 50%;
  width: 33.3%;
  overflow-y: auto;
  padding: 0.5rem;
  border: 1px solid #eee;
}
.image-wrapper::-webkit-scrollbar {
  display: none;
}

.overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.record-modal {
  z-index: 2;
  width: 50vw;
  max-height: 80vh;
  padding: 1em;
  background: #fff;
  overflow-y: auto;
}
.record-modal::-webkit-scrollbar {
  display: none;
}
.modal-button-container {
  height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}
.upload-content {
  width: 100%;
  height: 90%;
  display: flex;
}

.upload-file-container {
  width: 50%;
  padding: 0.5rem;
}
.delete-button-container {
  display: flex;
  justify-content: flex-end;
}
.delete-button {
  /* position: absolute; */
  text-align: center;
  font-weight: bold;
  width: 20px;
  height: 20px;
  /* bottom: 75px;
  right: 0; */
  line-height: 18px;
  background-color: #000;
  color: #eee;
  border-radius: 20px;
  cursor: pointer;
}
.upload-file {
  max-width: 100%;
  max-height: 60vh;
}
.datepicker-container {
  width: 50%;
  height: 100%;
  padding: 0.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
}
.datepicker {
  margin-top: 20px;
}

.textarea {
  width: 100%;
  min-height: 80px;
  padding: 0.5rem;
  border: 1px solid #ccc;
}
.input-file {
  display: none;
}
.image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.image-outer {
  height: 65%;
}
.image-outer:hover {
  cursor: pointer;
}
.post-date {
  height: 10%;
  text-align: center;
  border-bottom: 1px solid #eee;
}
.comment {
  height: 10%;
  /* margin-top: 0.5rem; */
}
.edit-button {
  height: 5%;
  text-align: right;
  /* margin-right: 100px; */
}

.edit-content {
  display: none;
  position: relative;
  /* margin: 1.5em 15px 1.5em 0; */
  /* padding: 7px 10px; */
  min-width: 120px;
  max-width: 100%;
  /* color: #555; */
  /* font-size: 16px; */
  background: #fff;
  border: solid 1px rgb(126, 126, 126);
  box-sizing: border-box;
}
/* .edit-content:before {
  content: "";
  position: absolute;
  top: 50%;
  right: -24px;
  margin-top: -12px;
  border: 12px solid transparent;
  border-left: 12px solid #fff;
  z-index: 2;
}
.edit-content:after {
  content: "";
  position: absolute;
  top: 50%;
  right: -30px;
  margin-top: -14px;
  border: 14px solid transparent;
  border-left: 14px solid #555;
  z-index: 1;
} */
.edit-button:hover .edit-content {
  display: inline-block;
  /* position: absolute; */
  /* left: -30px; */
  /* background-color: red; */
  /* top: 0; */
  color: rgb(126, 126, 126);
  font-size: 10px;
  text-align: right;
  cursor: pointer;
}
.popover-wrapper {
  display: flex;
  justify-content: flex-end;
}
.edit-dropdown {
  border-radius: 3px;
  border: 1px solid #e1e1e1;
}
.edit-dropdown:hover {
  border-radius: 3px;
  background-color: #e1e1e1;
}
.edit-modal {
  display: flex;
}
.edit-image {
  width: 50%;
  height: 100%;
  object-fit: contain;
}
</style>
