<template>
  <div class="wrapper">
    <div class="btn-container">
      <button class="green btn" @click="sendFeedbackContents">登録</button>
    </div>
    <div class="feedback-container">
      <div class="left-container">
        <div class="left-top">
          <div>タイトル：１</div>
          <input class="title" type="text" v-model="title1" />
          <div>内容</div>
          <textarea class="content" name="" id="" v-model="content1"></textarea>
        </div>
        <div class="left-bottom">
          <div>タイトル：３</div>
          <input class="title" type="text" v-model="title3" />
          <div>内容</div>
          <textarea class="content" name="" id="" v-model="content3"></textarea>
        </div>
      </div>
      <div class="right-container">
        <div class="right-top">
          <div>タイトル：２</div>
          <input class="title" type="text" v-model="title2" />
          <div>内容</div>
          <textarea class="content" name="" id="" v-model="content2"></textarea>
        </div>
        <div class="right-bottom">
          <div>タイトル：４</div>
          <input class="title" type="text" v-model="title4" />
          <div>内容</div>
          <textarea class="content" name="" id="" v-model="content4"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
const db = firebase.firestore();

export default {
  name: "FeedbackPage",
  components: {},
  data() {
    return {
      id: "",
      title1: "",
      title2: "",
      title3: "",
      title4: "",
      content1: "",
      content2: "",
      content3: "",
      content4: "",
    };
  },
  async created() {
    this.id = this.$store.state.userData.id;
    await this.fetchFeedbackSheet();
  },
  methods: {
    async sendFeedbackContents() {
      db.collection("users").doc(this.id).collection("feedbackSheet").add({
        title1: this.title1,
        title2: this.title2,
        title3: this.title3,
        title4: this.title4,
        content1: this.content1,
        content2: this.content2,
        content3: this.content3,
        content4: this.content4,
        createdAt: new Date(),
      });
    },
    async fetchFeedbackSheet() {
      await db
        .collection("users")
        .doc(this.id)
        .collection("feedbackSheet")
        .orderBy("createdAt", "desc")
        .limit(1)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.title1 = doc.data().title1;
            this.title2 = doc.data().title2;
            this.title3 = doc.data().title3;
            this.title4 = doc.data().title4;
            this.content1 = doc.data().content1;
            this.content2 = doc.data().content2;
            this.content3 = doc.data().content3;
            this.content4 = doc.data().content4;
          });
        });
    },
  },
};
</script>
<style scoped>
.feedback-container {
  display: flex;
  width: 100%;
  height: calc(100vh - 60px - 39px);
  background-color: #fafafa;
  /* padding: 0.5rem; */
}
.left-container,
.right-container {
  width: 50%;
}
.left-top,
.right-top,
.left-bottom,
.right-bottom {
  width: 100%;
  height: 50%;
  background-color: #bbb;
  padding: 0.5rem;
}
.left-top {
  background-color: rgb(226, 240, 217);
}
.right-top {
  background-color: rgb(218, 227, 243);
}
.left-bottom {
  background-color: rgb(255, 242, 204);
}
.right-bottom {
  background-color: rgb(251, 229, 214);
}
input {
  width: 100%;
  height: 10%;
  border: none;
  background-color: #fafafa;
  border-radius: 10px;
  /* margin-bottom: 0.3rem; */
}
textarea {
  width: 100%;
  height: 70%;
  border: none;
  background-color: #fafafa;
  border-radius: 10px;
  padding: 0.5rem;
}
</style>
