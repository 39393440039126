<template>
  <div class="about-type-container">
    <div class="wrapper">
      <div class="type-title">指導者タイプ：アレンジ型</div>
      <ul>
        <li>分析や検討をせずに、楽観的な判断を出すことがある</li>
        <li>承認することは上手だが、具体的な話が少し苦手</li>
      </ul>
      <table>
        <thead>
          <tr>
            <th>ユーザータイプ</th>
            <th>関わり方のポイント</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>結論</td>
            <td>承認よりも、はっきりとした具体的なアドバイスを簡潔に話すように心がける。</td>
          </tr>
          <tr>
            <td>根拠</td>
            <td>検査値等のデータを示しながら、論理的かつ計画的な指導を心がける。</td>
          </tr>
          <tr>
            <td>承認</td>
            <td>
              話が盛り上がりやすいので、脱線に気をつける。<br />
              計画性を持った指導を行う。
            </td>
          </tr>
          <tr>
            <td>着実</td>
            <td>指導者側が話しすぎないようにし、患者の考えを引き出すように心がける。</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArrangeType",
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.about-type-container {
  overflow-y: auto;
  padding-top: 0.5rem;
}
.wrapper {
  background-color: #fafafa;
}
.type-title {
  font-size: 1rem;
  font-weight: bold;
}
th,
td {
  border: 1px solid #bbb;
  padding: 0.5rem;
}
</style>
