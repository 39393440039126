<template>
  <div>
    <div class="chart-container">
      <VChart v-if="!isResize" @click="resizeGraph" :autoresize="true" :option="options" class="chart" />
      <!-- <button class="zoom-button" @click="resizeGraph">拡大</button> -->
    </div>
    <div v-if="isResize" class="modal">
      <div class="close-button" @click="resizeGraph">閉じる</div>
      <VChart :autoresize="true" :option="options" class="resize-chart" />
    </div>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, ScatterChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  GridComponent,
  AxisPointerComponent,
  LegendComponent,
  ToolboxComponent,
  TimelineComponent,
  CalendarComponent,
  GraphicComponent,
  TooltipComponent,
  DataZoomSliderComponent,
  DataZoomInsideComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  LineChart,
  ScatterChart,
  TitleComponent,
  GridComponent,
  LegendComponent,
  AxisPointerComponent,
  ToolboxComponent,
  TimelineComponent,
  CalendarComponent,
  GraphicComponent,
  BarChart,
  TooltipComponent,
  DataZoomSliderComponent,
  DataZoomInsideComponent,
]);

export default {
  name: "Graph",
  components: {
    VChart,
  },
  data() {
    return {
      isResize: false,
      options: {
        color: ["#FF7C5E"],
        title: {
          text: "体重",
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: 35,
          right: 5,
          left: 30,
        },
        xAxis: {
          type: "category",
          data: [
            "1/1",
            "1/2",
            "1/3",
            "1/4",
            "1/5",
            "1/6",
            "1/7",
            "1/8",
            "1/9",
            "1/10",
            "1/11",
            "1/12",
            "1/13",
            "1/14",
            "1/15",
            "1/16",
            "1/17",
            "1/18",
            "1/19",
            "1/20",
            "1/21",
            "1/22",
            "1/23",
          ],
        },
        yAxis: {
          type: "value",
          offset: -5,
          min: 66,
        },

        dataZoom: {
          startValue: "1/17",
          type: "slider",
        },
        // 拡大範囲選
        toolbox: {
          right: 40,
          feature: {
            restore: {},
            saveAsImage: {},
          },
        },
        series: [
          {
            data: [
              68.5, 68.3, 68.6, 68.6, 68.4, 68.5, 68.2, 68.1, 68.4, 68.0, 67.5, 68.2, 67.6, 67.3, 67.5, 66.8, 66.5,
              66.8, 66.4, 66.2, 66.4, 66.5, 66.5,
            ],
            type: "line",
            // showSymbol: false
          },
        ],
      },
    };
  },
  methods: {
    resizeGraph() {
      this.isResize = !this.isResize;
    },
  },
};
</script>

<style scoped>
.chart {
  height: calc((100vh - 60px - 39px) / 4 - 2.5rem);
}
.chart-container {
  position: relative;
}
.zoom-button {
  position: absolute;
  top: 0;
  right: 10px;
}
.resize-chart {
  background-color: #fafafa;
  padding: 1rem;
}
.modal {
  width: 800px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 30px;
  left: 30px;
  border: 1px solid #bbb;
  border-radius: 30px;
}
.close-button {
  background-color: #bbb;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
</style>
