<template>
  <div class="wrapper">
    <div class="information-container">
      <CTabs>
        <CTab title="情報">
          <UserInformation />
        </CTab>
        <CTab title="メモ">
          <Memo2 />
        </CTab>
        <CTab title="メッセージ画像">
          <SendImageList />
        </CTab>
        <CTab title="画像記録">
          <ImageRecording />
        </CTab>
        <CTab title="計測">
          <MeasuredValue />
        </CTab>
        <CTab title="タイプについて">
          <AboutType />
        </CTab>
        <CTab title="FB" v-if="role == 'eatas'">
          <FeedbackPage />
        </CTab>
      </CTabs>
    </div>
    <div class="time-line-container">
      <CTabs>
        <CTab :title="numberOfTabs != 2 ? 'メッセージ' : firstTabName">
          <NutritionistChat />
        </CTab>
        <CTab v-if="numberOfTabs == 2" :title="secondTabName">
          <TrainerChat />
        </CTab>
        <CTab title="サポートに相談">
          <SupportChat />
        </CTab>
      </CTabs>
    </div>
  </div>
</template>

<script>
import Memo from "@/views/components/Memo";
import Memo2 from "@/views/components/Memo2";
import UserInformation from "@/views/components/UserInformation";
import NutritionistChat from "@/views/components/NutritionistChat";
import TrainerChat from "@/views/components/TrainerChat";
import SupportChat from "@/views/components/SupportChat";
import ImageRecording from "@/views/components/ImageRecording";
import MeasuredValue from "@/views/components/MeasuredValue";
import FeedbackPage from "@/views/components/FeedbackPage";
import SendImageList from "@/views/components/SendImageList";
import AboutType from "@/views/components/AboutType";

export default {
  name: "UserPage",
  components: {
    Memo,
    UserInformation,
    NutritionistChat,
    TrainerChat,
    Memo2,
    ImageRecording,
    MeasuredValue,
    SupportChat,
    FeedbackPage,
    SendImageList,
    AboutType,
  },
  data() {
    return {
      firstTabName: this.$store.state.clientSettings.firstTabName,
      secondTabName: this.$store.state.clientSettings.secondTabName,
      numberOfTabs: this.$store.state.clientSettings.numberOfTabs,
      role: this.$store.state.clientUserData.clientUserData.role,
    };
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}
.information-container {
  width: 70%;
  height: calc(100vh - 60px);
}
.time-line-container {
  width: 30%;
  height: calc(100vh - 60px);
  background-color: #fafafa;
  padding-bottom: 0.5rem;
}
</style>
