<template>
  <div class="about-type-container">
    <div class="wrapper">
      <div>
        <h2>タイプ別栄養カウンセリング</h2>
        <h3>タイプ別の特徴</h3>
        <p>
          eat+アプリでは、4タイプ（結論、根拠、承認、着実）に加え、結果を重視しているか、プロセスを重視しているかをタイプに表示しています。<br />
          4タイプの特徴に合わせ、何を重視しているかを参考にし、日々のアドバイスに活かすことができます。
        </p>
        <p>
          また、食習慣・生活習慣アンケートの21番に、どのようなサポートを望むか確認していただいていますので、こちらも合わせてご覧ください。
        </p>
        <ul>
          <li>早く結果につながるためのサポート</li>
          <li>データや根拠を元にしたサポート</li>
          <li>できていることや努力に目を向け励ましてもらえるようなサポート</li>
          <li>褒められたり、共感されたりすることが多いサポート</li>
        </ul>
      </div>
      <div class="type-wrapper border-radius">
        <div class="type-title">結論タイプ（ディレクション型）</div>
        <div class="type-sub-title">はっきりとしたコミュニケーションを好むタイプ</div>
        <div>効率良く成果をあげることを重視し、力を発揮する。</div>
        <div class="point">【ポイント】</div>
        <ul>
          <li>早く結果を出したいので、「良い・悪い」をはっきりと指摘してほしい。</li>
          <li>「がんばってください」という力づけより、「何をどうすればいいのか」をはっきりと指摘されることを好む。</li>
          <li>うまくいっていないことを、はっきりと口に出す率直でストレートな会話も効果的。</li>
        </ul>
        <div>
          例）「血圧を下げるために一番効果的なことは、塩を抜くことです。」 「体重が◯kg減って、成果が出ていますね。」
        </div>
      </div>
      <div class="type-wrapper border-radius">
        <div class="type-title">根拠タイプ（ロジカル型）</div>
        <div class="type-sub-title">数値・データを基にしたコミュニケーションを望むタイプ</div>
        <div>正確性を重視し、根拠のある確実なデータ、事実、方法に基づいて物事を進める。</div>
        <div class="point">【ポイント】</div>
        <ul>
          <li>
            「事実の会話」が大切。本人の今の状態や、感情に焦点を当てるのではなく、今どのように解決するのかという会話が有効。
          </li>
          <li>
            数字やデータなどを処理するのが得意なので、検査結果や体重記録、食事記録など数字やデータを使った会話が効果的。
          </li>
        </ul>
        <div>例）「体重が◯kgから◯kgになっていますが、どのような工夫をされましたか。」</div>
      </div>
      <div class="type-wrapper border-radius">
        <div class="type-title">承認タイプ（アレンジ型）</div>
        <div class="type-sub-title">承認で動機づけされるタイプ。</div>
        <div>話好きで楽観的である。しかし、目標達成に強い意志を持たないことがある。</div>
        <div class="point">【ポイント】</div>
        <ul>
          <li>病気や体型に対する重要性の認識が低く、楽観的に考える傾向がある。</li>
          <li>根気に欠ける面があり、問題の本質を避けて通ることがある。</li>
          <li>
            単純作業やルーティンワークが得意でない人もいるので、体重記録や食事記録等を指導に使用する際は気をつける。
          </li>
          <li>承認で動機づけられるので、承認を十分に取り入れた会話と心がけることが最大のポイント。</li>
        </ul>
        <div>例）「前回より体重が1kg減っていますが、周りの人から何か言われていますか。」</div>
      </div>
      <div class="type-wrapper border-radius">
        <div class="type-title">着実タイプ（バランス型）</div>
        <div class="type-sub-title">小さな努力を認めることが有効なタイプ。</div>
        <div>指導者のアドバイスを受け入れやすいが、できなかったことに対する不安を感じることもある。</div>
        <div class="point">【ポイント】</div>
        <ul>
          <li>承認よりも感謝や労いの言葉がけをする。</li>
          <li>がんばりや努力など、結果だけでなくプロセスに焦点を当てる。</li>
          <li>今、できているところに焦点をあて、継続できるように背中を押す支援が効果的。</li>
          <li>意見や考え、質問を口に出さない傾向があるので、開かれた質問を使って話を促すことが必要。</li>
        </ul>
        <div>
          例）「コツコツ努力をされているので、結果はいずれでますよ。いつも〇〇していただきありがとうございます。」
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserTypeDescription",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.about-type-container {
  overflow-y: auto;
  padding: 0.5rem;
  height: calc(100vh - 60px - 39px);
}
.wrapper {
  background-color: #fafafa;
  padding: 0 0.5rem;
}
.type-title {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}
.type-sub-title {
  font-weight: bold;
  text-align: center;
  padding-bottom: 1rem;
}
.type-sub-title::before,
.type-sub-title::after {
  content: "ー";
}
.type-wrapper {
  background-color: rgb(237, 237, 237);
  padding: 1rem;
}
</style>
