<template>
  <div class="about-type-container">
    <div class="wrapper">
      <div class="type-title">指導者タイプ：ロジカル型</div>
      <ul>
        <li>自分流のやり方にこだわり、ペースを乱されるのが嫌い</li>
        <li>情報を十分にそろえて分析を行うため、判断が遅い</li>
      </ul>
      <table>
        <thead>
          <tr>
            <th>ユーザータイプ</th>
            <th>関わり方のポイント</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>結論</td>
            <td>結論をまず伝え、根拠は簡潔に述べる。</td>
          </tr>
          <tr>
            <td>根拠</td>
            <td>基準が高くなりすぎないように気をつける。</td>
          </tr>
          <tr>
            <td>承認</td>
            <td>
              体重や食事の記録に対して、完璧を押し付けない。<br />
              患者の話をよく聞き、承認を心がける。
            </td>
          </tr>
          <tr>
            <td>着実</td>
            <td>できているところに着目し、後押しをする。</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogicalType",
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.about-type-container {
  overflow-y: auto;
  padding-top: 0.5rem;
}
.wrapper {
  background-color: #fafafa;
}
.type-title {
  font-size: 1rem;
  font-weight: bold;
}
th,
td {
  border: 1px solid #bbb;
  padding: 0.5rem;
}
</style>
