<template>
  <div class="about-type-container">
    <div class="wrapper">
      <div class="type-title">指導者タイプ：バランス型</div>
      <ul>
        <li>決断に時間がかかる</li>
        <li>話を聞きすぎる傾向がある</li>
      </ul>
      <table>
        <thead>
          <tr>
            <th>ユーザータイプ</th>
            <th>関わり方のポイント</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>結論</td>
            <td>はっきりとアドバイスをすることを心がける。</td>
          </tr>
          <tr>
            <td>根拠</td>
            <td>数値やデータを基にした会話を心がけ、事実を明確に伝える。</td>
          </tr>
          <tr>
            <td>承認</td>
            <td>話を聞きすぎて、本題から逸れてしまわないように気をつける。</td>
          </tr>
          <tr>
            <td>着実</td>
            <td>配慮しすぎないように気をつける。</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "BalanceType",
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.about-type-container {
  overflow-y: auto;
  padding-top: 0.5rem;
}
.wrapper {
  background-color: #fafafa;
}
.type-title {
  font-size: 1rem;
  font-weight: bold;
}
th,
td {
  border: 1px solid #bbb;
  padding: 0.5rem;
}
</style>
