<template>
  <div class="memo-container">
    <div class="monthly-list">2021年11月</div>
    <div class="date-list">
      <div>
        <div>11月1日</div>
        <div></div>
      </div>
    </div>
    <div class="memo-area">
      <textarea name="" id=""></textarea>
    </div>
  </div>
</template>

<script>
export default {
  name: "Memo",
};
</script>

<style scoped>
.memo-container {
  width: 100%;
  height: calc(100vh - 60px - 39px);
  /* ヘッダーの高さ - タブの高さ */
  display: flex;
  background-color: #fbbb;
}
.monthly-list {
  width: 12%;
  background-color: #fafafa;
}
.date-list {
  width: 23%;
  background-color: cadetblue;
}
.memo-area {
  width: 65%;
}
textarea {
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
}
</style>
