<template>
  <div class="measured-value-container">
    <div class="button-container">
      <button class="blue btn" @click="openRecordModal">計測値入力</button>
      <button class="yellow btn" @click="openRecordModal">修正</button>
    </div>
    <div class="value-container">
      <div class="left-value-container-container">
        <div class="graph-3equal border-radius">
          <MeasuredWeightChart />
        </div>
        <div class="graph-3equal border-radius">
          <MeasuredBodyFatPercentageChart />
        </div>
        <div class="graph-3equal border-radius">
          <MeasuredBodyFatWeightChart />
        </div>
        <div class="graph-3equal border-radius">
          <MeasuredMusclePercentageChart />
        </div>
      </div>
      <div class="right-value-container-container">
        <div class="graph-3equal border-radius">
          <MeasuredBodyAgeChart />
        </div>
        <div class="graph-3equal border-radius">
          <MeasuredBasalMetabolismChart />
        </div>
        <div class="graph-3equal border-radius">
          <MeasuredVisceralFatLevelChart />
        </div>
        <div class="graph-3equal border-radius">
          <SubMeasuredValue />
        </div>
      </div>

      <!-- モーダルウィンドウ -->
      <div class="overlay" v-show="this.$store.state.showRecordModal">
        <div class="record-modal border-radius">
          <button class="close-button pink btn" @click="closeRecordModal">閉じる</button>
          <MeasuredValueModal />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WeightChart from "./WeightChart";
import MeasuredValueModal from "./MeasuredValueModal";
import Graph from "@/views/components/Graph/Graph";
import MeasuredWeightChart from "@/views/components/Graph/MeasuredWeightChart";
import MeasuredBasalMetabolismChart from "@/views/components/Graph/MeasuredBasalMetabolismChart";
import MeasuredBodyAgeChart from "@/views/components/Graph/MeasuredBodyAgeChart";
import MeasuredBodyFatPercentageChart from "@/views/components/Graph/MeasuredBodyFatPercentageChart";
import MeasuredVisceralFatLevelChart from "@/views/components/Graph/MeasuredVisceralFatLevelChart";
import MeasuredBodyFatWeightChart from "@/views/components/Graph/MeasuredBodyFatWeightChart";
import MeasuredMusclePercentageChart from "@/views/components/Graph/MeasuredMusclePercentageChart";
import SubMeasuredValue from "@/views/components/Graph/SubMeasuredValue";

export default {
  name: "MeasuredValue",
  components: {
    WeightChart,
    MeasuredValueModal,
    Graph,
    MeasuredWeightChart,
    MeasuredBodyFatPercentageChart,
    MeasuredBodyFatWeightChart,
    MeasuredBasalMetabolismChart,
    MeasuredBodyAgeChart,
    MeasuredVisceralFatLevelChart,
    MeasuredMusclePercentageChart,
    SubMeasuredValue,
  },
  data() {
    return {
      upperArm: null,
      west: null,
      hip: null,
      femur: null,
      lowerLeg: null,
    };
  },
  methods: {
    openRecordModal() {
      this.$store.commit("openRecordModal");
    },
    closeRecordModal() {
      this.$store.commit("closeRecordModal");
    },
  },
};
</script>

<style scoped>
.measured-value-container {
  padding: 0.5rem;
  height: calc(100vh - 60px - 39px);
}
.value-container {
  height: 95%;
  display: flex;
}
.left-value-container-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.left-value-container-container::-webkit-scrollbar {
  display: none;
}
.right-value-container-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.right-value-container-container::-webkit-scrollbar {
  display: none;
}
.graph-3equal {
  height: 33.3%;
  background-color: #fafafa;
  /* display: flex; */
}
.graph-4equal {
  height: 25%;
  background-color: #fafafa;
  overflow-y: auto;
  /* display: flex; */
}
.graph-4equal::-webkit-scrollbar {
  display: none;
}
.overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.record-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 2;
  padding: 1em;
  background: #fff;
  overflow-y: auto;
}
.record-modal::-webkit-scrollbar {
  display: none;
}
.close-button {
  margin-bottom: 1rem;
}
.button-container {
  height: 5%;
  display: flex;
  justify-content: flex-start;
}
.btn {
  margin-left: 0.5rem;
}
@media (max-height: 900px) {
  .graph-3equal {
    height: 50%;
    background-color: #fafafa;
    /* display: flex; */
  }
}
</style>
