<template>
  <div class="about-type-container">
    <div class="wrapper">
      <div class="type-title">指導者タイプ：ディレクション型</div>
      <ul>
        <li>成果や結果にこだわる</li>
        <li>承認が苦手</li>
        <li>ストレートな物言いで、言葉足らずになることがある</li>
      </ul>
      <table>
        <thead>
          <tr>
            <th>ユーザータイプ</th>
            <th>関わり方のポイント</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>結論</td>
            <td>説明が不十分になることがある。</td>
          </tr>
          <tr>
            <td>根拠</td>
            <td>あまり急かさないように注意する。</td>
          </tr>
          <tr>
            <td>承認</td>
            <td>承認を心がける。</td>
          </tr>
          <tr>
            <td>着実</td>
            <td>
              結果よりもプロセスに着目する。<br />
              強い口調にならないように気をつける。
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "DirectionType",
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.about-type-container {
  overflow-y: auto;
  padding-top: 0.5rem;
}
.wrapper {
  background-color: #fafafa;
}
.type-title {
  font-size: 1rem;
  font-weight: bold;
}
th,
td {
  border: 1px solid #bbb;
  padding: 0.5rem;
}
</style>
