<template>
  <div>
    <div class="image-recording-container">
      <div class="image-container">
        <div class="user-information border-radius">
          <div v-for="image in images" :key="image.createdAt" class="image-wrapper">
            <img
              class="image"
              :src="Array.isArray(image.image) ? image.image[0] : image.image"
              @click="openImageModal(image.image)"
              alt=""
            />
            <div class="post-date">{{ image.date }}</div>
            <div class="comment">{{ image.comment }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 画像拡大モーダル -->
    <CModal centered size="lg" :show.sync="showEnlargeModal">
      <img class="modal-image" :src="enlargeModalSrc" alt="" />
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
  </div>
</template>

<script>
import firebase from "firebase";

const db = firebase.firestore();
const convertFromTimestampToDatetime = (timestamp) => {
  const _d = timestamp ? new Date(timestamp * 1000) : new Date();
  const Y = _d.getFullYear();
  const m = (_d.getMonth() + 1).toString().padStart(2, "0");
  const d = _d.getDate().toString().padStart(2, "0");
  const H = _d.getHours().toString().padStart(2, "0");
  const i = _d.getMinutes().toString().padStart(2, "0");
  const s = _d.getSeconds().toString().padStart(2, "0");
  return `${m}/${d} ${H}:${i}`;
  // return `${Y}/${m}/${d} ${H}:${i}:${s}`;
};

export default {
  name: "ImageRecording",
  data() {
    return {
      id: this.$store.state.userData.id,
      showAddImageModal: false,
      fileName: "",
      uploadFileUrl: null,
      uploadFileUrl2: null,
      DatePickerFormat: "yyyy-MM-dd",
      date: new Date(),
      comment: "",
      images: [],
      showEnlargeModal: false,
      enlargeModalSrc: null,
    };
  },
  created() {
    this.fetchImages();
  },
  methods: {
    fetchImages() {
      db.collection("chats")
        .doc(this.id)
        .collection("nutritionistChat")
        .orderBy("createdAt", "desc")
        .onSnapshot((querySnapshot) => {
          let allImages = [];
          querySnapshot.forEach((doc) => {
            const imageData = doc.data().image;
            const comment = doc.data().text;
            const date = convertFromTimestampToDatetime(doc.data().createdAt.seconds);

            if (imageData != null) {
              if (Array.isArray(imageData)) {
                //  画像が複数ある場合
                imageData.forEach((imageItem) => {
                  allImages.push({
                    comment: comment,
                    date: date,
                    image: imageItem,
                  });
                });
              } else {
                //  画像が1枚の場合
                allImages.push({
                  comment: comment,
                  date: date,
                  image: imageData,
                });
              }
            }
          });
          this.images = allImages;
        });
    },
    openImageModal(src) {
      this.enlargeModalSrc = src;
      this.showEnlargeModal = true;
    },
    calculateGridColumns(imageArray) {
      return imageArray.length >= 5 ? "repeat(3, 1fr)" : "repeat(2, 1fr)";
    },
  },
};
</script>

<style scoped>
.image-recording-container {
  padding: 0.5rem;
  /* ヘッダーの高さ - タブの高さ */
  height: calc(100vh - 60px);
}
.image-container {
  height: 95%;
  display: flex;
  flex-direction: column;
}
.user-information {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  background-color: #fafafa;
  overflow-y: auto;
}
.user-information::-webkit-scrollbar {
  display: none;
}
.image-wrapper {
  width: 33.3%;
  padding: 0.5rem;
  border: 1px solid #eee;
}
.image-wrapper::-webkit-scrollbar {
  display: none;
}
.image {
  max-height: 320px;
  width: 100%;
  object-fit: contain;
}
.image:hover {
  cursor: pointer;
}
.post-date {
  text-align: center;
  border-bottom: 1px solid #eee;
  background-color: rgb(248, 238, 224);
}
.modal-image {
  height: 80vh;
  width: 100%;
  object-fit: contain;
}
.comment {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.image-grid {
  display: grid;
  gap: 5px;
}
</style>
