<template>
  <div class="about-type-container">
    <div class="wrapper border-radius">
      <CTabs>
        <CTab title="ユーザータイプについて"><UserTypeDescription /></CTab>
        <CTab title="自身のタイプについて"><MyTypeDescription /></CTab>
      </CTabs>
    </div>
  </div>
</template>

<script>
import UserTypeDescription from "@/views/components/TypeDescription/UserTypeDescription";
import MyTypeDescription from "@/views/components/TypeDescription/MyTypeDescription";
export default {
  name: "AboutType",
  components: { UserTypeDescription, MyTypeDescription },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.about-type-container {
  overflow-y: auto;
  padding: 0.5rem;
  height: calc(100vh - 60px - 39px);
}
.wrapper {
  background-color: #fafafa;
  padding: 1rem;
}
.type-title {
  font-size: 1rem;
  font-weight: bold;
}
</style>
