<template>
  <div class="user-basic-information">
    <div class="user-information-container">
      <div class="user-image">
        <img class="" :src="userPhotoUrl" alt="" />
      </div>
      <div class="user-information-table">
        <div class="user-type">{{ userType }}</div>
        <table>
          <tr>
            <th class="head">名前</th>
            <td class="body">{{ lastName }} {{ firstName }} 様</td>
          </tr>
          <tr>
            <th class="head">よみがな</th>
            <td class="body">{{ lastNameRuby }} {{ firstNameRuby }} 様</td>
          </tr>
          <tr>
            <th class="head">生年月日</th>
            <td class="body">{{ birthday }}</td>
          </tr>
          <tr>
            <th class="head">年齢</th>
            <td class="body">{{ age }}</td>
          </tr>
          <tr>
            <th class="head">性別</th>
            <td class="body">{{ sex }}</td>
          </tr>
          <tr>
            <th class="head">住所</th>
            <td class="body">{{ address }}</td>
          </tr>
          <tr>
            <th class="head">身長</th>
            <td class="body">{{ height }}cm</td>
          </tr>
          <tr>
            <th class="head">登録時体重</th>
            <td class="body">{{ weight }}kg</td>
          </tr>
          <tr>
            <th class="head">BMI</th>
            <td>{{ bmi }}</td>
          </tr>
          <tr v-if="course">
            <th class="head">コース</th>
            <td class="body">{{ course }}</td>
          </tr>
          <tr v-if="numberOfTimes">
            <th class="head">回数</th>
            <td class="body">{{ numberOfTimes }}回</td>
          </tr>
          <tr v-if="methodOfPayment">
            <th class="head">支払い方法</th>
            <td>{{ methodOfPayment }}</td>
          </tr>
          <!-- <tr>
          <td class="head">タイプ</td>
          <td>結論ー結論</td>
        </tr> -->
        </table>
      </div>
    </div>
    <div class="user-target-container">
      <table>
        <tr>
          <th class="head">目標</th>
          <td>{{ target }}</td>
        </tr>
        <tr>
          <th class="head">何のために</th>
          <td>{{ why }}</td>
        </tr>
        <tr>
          <th class="head">いつまでに</th>
          <td>{{ deadline }}</td>
        </tr>
        <tr>
          <th class="head">何をする</th>
          <td>{{ whatToDo1 }}</td>
        </tr>
        <tr>
          <th class="head">何をする</th>
          <td>{{ whatToDo2 }}</td>
        </tr>
        <tr>
          <th class="head">何をする</th>
          <td>{{ whatToDo3 }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import convertFrom8digitBirthdayToDate from "@/utils/convertFrom8digitBirthdayToDate";

export default {
  name: "UserBasicInformation",
  components: {},
  data() {
    return {
      id: this.$store.state.userData.id,
      lastName: this.$store.state.userData.lastName,
      firstName: this.$store.state.userData.firstName,
      lastNameRuby: this.$store.state.userData.lastNameRuby,
      firstNameRuby: this.$store.state.userData.firstNameRuby,
      sex: this.$store.state.userData.sex,
      birthday: convertFrom8digitBirthdayToDate(this.$store.state.userData.birthday),
      age: this.$store.state.userData.age,
      height: this.$store.state.userData.height,
      weight: this.$store.state.userData.weight,
      postalCode: this.$store.state.userData.postalCode,
      address: this.$store.state.userData.address,
      course: this.$store.state.userData.course,
      numberOfTimes: this.$store.state.userData.numberOfTimes,
      methodOfPayment: this.$store.state.userData.methodOfPayment,
      userType: this.$store.state.userData.userType,
      bmi:
        Math.round(
          (this.$store.state.userData.weight /
            (this.$store.state.userData.height * 0.01) /
            (this.$store.state.userData.height * 0.01)) *
            10
        ) / 10,
      deadline: "",
      target: "",
      whatToDo1: "",
      whatToDo2: "",
      whatToDo3: "",
      why: "",
      userPhotoUrl:
        "https://firebasestorage.googleapis.com/v0/b/eatas-app-4cfb1.appspot.com/o/icon%2Fdefault-user-icon.jpeg?alt=media&token=3925b928-b03c-419f-97aa-82f8680297d4",
    };
  },
  methods: {},
  async created() {
    this.checkUserImage();
    firebase
      .firestore()
      .collection("users")
      .doc(this.id)
      .collection("target")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.deadline = doc.data().deadline;
          this.target = doc.data().target;
          this.whatToDo1 = doc.data().whatToDo1;
          this.whatToDo2 = doc.data().whatToDo2;
          this.whatToDo3 = doc.data().whatToDo3;
          this.why = doc.data().why;
          // if (doc.data().why !== "") {
          //   this.why = doc.data().why;
          // } else {
          //   this.why = "目標を設定するように促しましょう。";
          // }
        });
      });
    // firebase
    //   .firestore()
    //   .collection("images")
    //   .doc(this.id)
    //   .get()
    //   .then((doc) => {
    //     console.log(doc.data());
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  },
  methods: {
    checkUserImage() {
      if (this.$store.state.userData.userImage) {
        this.userPhotoUrl = this.$store.state.userData.userImage;
      }
    },
  },
};
</script>
<style scoped>
.title {
  padding-left: 1rem;
  font-weight: bold;
  font-size: 1.2rem;
}
.user-basic-information {
  height: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}
.user-information-container {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.5rem;
}
.user-target-container::-webkit-scrollbar {
  display: none;
}
.user-information-table {
  width: 60%;
  margin-left: 0.5rem;
}
.user-information-table::-webkit-scrollbar {
  display: none;
}
.user-information-table table {
  width: 100%;
}
.user-image {
  max-width: 40%;
  margin-right: 0.5rem;
  max-height: 100%;
  display: flex;
}
.user-image img {
  object-fit: cover;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  border-radius: 10px;
  object-fit: contain;
}
.head {
  font-weight: bold;
}
tr {
  border-bottom: solid 1px #bbb;
}
.user-target-container {
  margin-top: 0.5rem;
}
.user-target-container table {
  width: 100%;
}
.user-type {
  text-align: center;
  background-color: #ff866b;
  color: #fafafa;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 3px;
  border: 0;
  font-weight: bold;
  transition: 0.3s;
  line-height: 14px;
}
@media only screen and (max-width: 1200px) {
  /* .user-information-container {
    display: flex;
    flex-direction: column;
  } */
  .user-information-table {
    width: 100%;
    margin-left: 0;
    /* overflow-y: auto; */
  }
  .user-information-table::-webkit-scrollbar {
    display: none;
  }
  .user-information-table table {
    width: 100%;
  }
  .user-image {
    max-width: 100%;
  }
  .user-image img {
    max-height: 100px;
  }
  .user-information-container {
    height: none;
  }
  .user-target-container {
    height: none;
  }
}
</style>
