<template>
  <div>
    <div class="chart-container">
      <VChart v-if="!isResize" @click="resizeGraph" :autoresize="true" :option="options" class="chart" />
    </div>
    <div v-if="isResize" class="modal">
      <div class="close-button" @click="resizeGraph">閉じる</div>
      <VChart :autoresize="true" :option="options" class="resize-chart" />
    </div>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, ScatterChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  GridComponent,
  AxisPointerComponent,
  LegendComponent,
  ToolboxComponent,
  TimelineComponent,
  CalendarComponent,
  GraphicComponent,
  TooltipComponent,
  DataZoomSliderComponent,
  DataZoomInsideComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import firebase from "firebase";

const db = firebase.firestore();

use([
  CanvasRenderer,
  LineChart,
  ScatterChart,
  TitleComponent,
  GridComponent,
  LegendComponent,
  AxisPointerComponent,
  ToolboxComponent,
  TimelineComponent,
  CalendarComponent,
  GraphicComponent,
  BarChart,
  TooltipComponent,
  DataZoomSliderComponent,
  DataZoomInsideComponent,
]);

export default {
  name: "WeightChart",
  components: {
    VChart,
  },
  data() {
    return {
      isResize: false,
      id: this.$store.state.userData.id,
      height: this.$store.state.userData.height,
      options: {
        color: ["#FF7C5E"],
        title: {
          text: "体重",
        },
        tooltip: {
          trigger: "axis",
          formatter: (params) => {
            // ツールチップに表示する内容を生成する
            const bmi = Math.round((params[0].value / (this.height * 0.01) / (this.height * 0.01)) * 10) / 10;
            return `<div>${params[0].axisValueLabel} <br /><span style="font-weight: bold;"> 体重： ${params[0].value} <br /> BMI： ${bmi}</span></div>`;
          },
        },
        grid: {
          top: 35,
          right: 5,
          left: 40,
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
          offset: -5,
          min: null,
        },
        dataZoom: {
          // 要修正
          startValue: null,
          type: "slider",
          // moveOnMouseWheel: true,
          // zoomOnMouseWheel: 'ctrl',
        },
        // 拡大範囲選
        toolbox: {
          feature: {
            // dataZoom: {
            //   yAxisIndex: "none",
            // },
            restore: {},
            saveAsImage: {},
          },
        },
        series: [
          {
            name: "体重",
            type: "line",
            connectNulls: true,
            data: [],
          },
        ],
      },
    };
  },
  async created() {
    await db
      .collection("users")
      .doc(this.id)
      .collection("record")
      .orderBy(firebase.firestore.FieldPath.documentId())
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().weight !== null && doc.data().weight !== "") {
            this.options.xAxis.data.push(doc.data().date);
            this.options.series[0].data.push(doc.data().weight);
          }
        });
        this.options.yAxis.min = Math.min(...this.options.series[0].data);
      });
  },
  methods: {
    resizeGraph() {
      this.isResize = !this.isResize;
    },
  },
};
</script>

<style scoped>
.chart {
  height: calc((100vh - 60px - 39px) / 3 - 3rem);
}
.resize-chart {
  background-color: #fafafa;
  padding: 1rem;
}
.modal {
  width: 800px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 30px;
  left: 30px;
  border: 1px solid #bbb;
  border-radius: 30px;
}
.close-button {
  background-color: #bbb;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.zoom-button {
  position: relative;
  top: 20px;
}
@media (max-height: 800px) {
  .chart {
    height: calc((100vh - 60px - 39px - 30px - 1rem) / 2 - 1.6rem);
  }
}
</style>
