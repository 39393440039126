<template>
  <div>
    <CCardBody class="">
      <h1>ユーザー情報</h1>
      <div class="measureed-value-modal-container">
        <div class="left-container">
          <label class="upload-file-button blue btn"
            >ユーザーの写真を変更する
            <input class="input-file" type="file" name="datafile" ref="preview" @change="uploadUserImageFile" />
          </label>
          <div class="upload-file-container">
            <div class="delete-button-container" v-if="uploadUserImageFileUrl">
              <div class="delete-button" @click="deletePreview">×</div>
            </div>
            <img class="upload-file" v-if="uploadUserImageFileUrl" :src="uploadUserImageFileUrl" alt="" />
          </div>
        </div>
        <div class="middle-container">
          <div class="input-box">
            <div class="small-input">
              <div>名前：性</div>
              <input v-model="userData.lastName" class="input" type="text" />
            </div>
            <div class="small-input">
              <div>名</div>
              <input v-model="userData.firstName" class="input" type="text" />
            </div>
          </div>
          <div class="input-box">
            <div class="small-input">
              <div>ふりがな：せい</div>
              <input v-model="userData.lastNameRuby" class="input" type="text" />
            </div>
            <div class="small-input">
              <div>（めい）</div>
              <input v-model="userData.firstNameRuby" class="input" type="text" />
            </div>
          </div>
          <div class="input-box">
            <div class="small-input">
              <div>生年月日</div>
              <input v-model="userData.birthday" class="input" type="number" min="0" />
            </div>
          </div>
          <div class="input-box">
            <div class="">
              <div>性別</div>
              <input v-model="userData.sex" name="sex" id="male" type="radio" value="男性" />
              <label for="male">男性</label>
              <input v-model="userData.sex" name="sex" id="female" type="radio" value="女性" />
              <label for="female">女性</label>
              <input v-model="userData.sex" name="sex" id="queer" type="radio" value="どちらでもない" />
              <label for="queer">どちらでもない</label>
            </div>
          </div>
          <div class="input-box">
            <div class="small-input">
              <div>郵便番号（7ケタ）</div>
              <input v-model="userData.postalCode" class="input" type="text" min="0" />
            </div>
          </div>
          <div class="input-box">
            <div class="small-input">
              <div>都道府県</div>
              <input v-model="userData.address1" class="input" type="text" />
            </div>
          </div>
          <div class="input-box">
            <div class="small-input">
              <div>市区町村</div>
              <input v-model="userData.address2" class="input" type="text" />
            </div>
          </div>
        </div>
        <div class="right-container">
          <div class="input-box">
            <div class="small-input">
              <div>身長</div>
              <input v-model="userData.height" class="input" type="number" step="0.1" min="0" />cm
            </div>
          </div>
          <div class="input-box">
            <div class="small-input">
              <div>体重</div>
              <input v-model="userData.weight" class="input" type="number" step="0.1" min="0" />kg
            </div>
          </div>
        </div>
        <div class="right-container">
          <div class="input-box">
            <div class="small-input">
              <div>コース</div>
              <input v-model="userData.course" class="input" type="text" />
            </div>
          </div>
          <div class="input-box">
            <div class="small-input">
              <div>回数</div>
              <input v-model="userData.numberOfTimes" class="input" type="number" step="1" min="0" />回
            </div>
          </div>
          <div class="input-box">
            <div class="small-input">
              <div>支払い方法</div>
              <input v-model="userData.methodOfPayment" class="input" type="text" />
            </div>
          </div>
        </div>
      </div>
      <button class="add-button btn green" @click="updateUserInformation">修正</button>
    </CCardBody>
  </div>
</template>

<script>
import firebase from "firebase";
const db = firebase.firestore();
const month = new Date().getMonth() + 1;
const date = new Date().getDate();

export default {
  name: "UpdateUserInformationModal",
  data() {
    return {
      userData: {
        id: "",
        lastName: "",
        firstName: "",
        lastNameRuby: "",
        firstNameRuby: "",
        sex: "",
        birthday: "",
        height: "",
        weight: "",
        postalCode: "",
        address1: "",
        address2: "",
        course: "",
        numberOfTimes: "",
        methodOfPayment: "",
      },
      userImageFileName: "",
      uploadUserImageFileUrl: null,
      uploadUserImageFileUrl2: null,
    };
  },
  created() {
    this.userData.id = this.$store.state.userData.id;
    this.userData.lastName = this.$store.state.userData.lastName;
    this.userData.firstName = this.$store.state.userData.firstName;
    this.userData.lastNameRuby = this.$store.state.userData.lastNameRuby;
    this.userData.firstNameRuby = this.$store.state.userData.firstNameRuby;
    this.userData.sex = this.$store.state.userData.sex;
    this.userData.birthday = this.$store.state.userData.birthday;
    this.userData.height = this.$store.state.userData.height;
    this.userData.weight = this.$store.state.userData.weight;
    this.userData.postalCode = this.$store.state.userData.postalCode;
    this.userData.address1 = this.$store.state.userData.address1;
    this.userData.address2 = this.$store.state.userData.address2;
    // 直接数値を入れると初期値に設定のない数値なのでinvalid valueエラーになる
    if (this.$store.state.userData.course) {
      this.userData.course = this.$store.state.userData.course;
    }
    if (this.$store.state.userData.numberOfTimes) {
      this.userData.numberOfTimes = this.$store.state.userData.numberOfTimes;
    }
    if (this.$store.state.userData.methodOfPayment) {
      this.userData.methodOfPayment = this.$store.state.userData.methodOfPayment;
    }
  },
  methods: {
    async updateUserInformation() {
      await db.collection("users").doc(this.userData.id).update({
        id: this.userData.id,
        lastName: this.userData.lastName,
        firstName: this.userData.firstName,
        lastNameRuby: this.userData.lastNameRuby,
        firstNameRuby: this.userData.firstNameRuby,
        sex: this.userData.sex,
        birthday: this.userData.birthday,
        height: this.userData.height,
        weight: this.userData.weight,
        postalCode: this.userData.postalCode,
        address1: this.userData.address1,
        address2: this.userData.address2,
        course: this.userData.course,
        numberOfTimes: this.userData.numberOfTimes,
        methodOfPayment: this.userData.methodOfPayment,
      });
      this.$store.commit("getUserData", this.userData);
      this.saveImage();
      this.$store.commit("closeUpdateUserInformationModal");
    },
    uploadUserImageFile() {
      const file = this.$refs.preview.files[0];
      this.userImageFileName = this.$refs.preview.files[0].name;
      this.uploadUserImageFileUrl = URL.createObjectURL(file);
      let reader = new FileReader();
      reader.readAsDataURL(file);
      this.uploadUserImageFileUrl2 = reader;

      this.$refs.preview.value = "";
    },
    deletePreview() {
      this.uploadUserImageFileUrl = "";
      URL.revokeObjectURL(this.uploadUserImageFileUrl);
    },
    async saveImage() {
      if (this.uploadUserImageFileUrl) {
        // const userImageDocRef = await this.$store.dispatch(
        //   "createUserImageDocRef"
        // );
        const ext = this.userImageFileName.split(".").pop();
        const storagePath = `userImages/${this.userData.id}.${ext}`;
        // console.log(storagePath);
        const downloadUrl = await this.uploadImage(this.uploadUserImageFileUrl2.result, storagePath);
        db.collection("users").doc(this.userData.id).set(
          {
            userImage: downloadUrl,
          },
          { merge: true }
        );
        this.$store.commit("updateUserDataImage", downloadUrl);
        this.deletePreview();
      } else {
        // console.log("画像の変更なし");
      }
      this.comment = null;
    },
    async uploadImage(uri, path) {
      // uriをblobに変換
      const localUri = await fetch(uri);
      const blob = await localUri.blob();
      const ref = firebase.storage().ref().child(path);

      let downloadUrl = "";
      try {
        await ref.put(blob);
        downloadUrl = await ref.getDownloadURL();
      } catch (err) {
        // console.log(err);
      }
      return downloadUrl;
    },
  },
};
</script>
<style scoped>
.name {
  display: flex;
}

input {
  border: solid 1px #d8dbe0;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  color: #768192;
  margin-right: 0.5rem;
}
input[type="radio"] {
  margin: 5px;
}

.input-file {
  display: none;
}
.small-input {
  margin-right: 1rem;
}

.input-box {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: flex-end;
}
.measureed-value-modal-container {
  display: flex;
}
.card-body {
  display: flex;
  flex-direction: column;
}
.middle-container {
  padding-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}
.right-container {
  padding-left: 1rem;
  margin-top: 1rem;
}
.left-container {
  /* display: flex; */
  padding-right: 1rem;
}
.upload-file-button {
  margin-top: 1rem;
}

.upload-file-container {
  /* width: 50%; */
  padding: 0.5rem;
  text-align: center;
}
.delete-button-container {
  display: flex;
  justify-content: flex-end;
}
.delete-button {
  /* position: absolute; */
  text-align: center;
  font-weight: bold;
  width: 20px;
  height: 20px;
  /* bottom: 75px;
  right: 0; */
  line-height: 18px;
  background-color: #000;
  color: #eee;
  border-radius: 20px;
  cursor: pointer;
}
.upload-file {
  max-width: 100%;
  height: 180px;
  /* max-height: 60vh; */
  /* height: calc(100% - 20px); */
  /* object-fit: cover; */
  /* position: relative; */
}
</style>
