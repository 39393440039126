<template>
  <div class="memo-container">
    <!-- <div class="monthly-list">
      2021年11月
      <div></div>
    </div> -->
    <div class="date-list">
      <div
        class="memoList"
        v-for="(memo, index) in memos"
        :key="index"
        @click="selectMemo(index)"
        :data-selected="index == selectedIndex"
      >
        <p class="memoTitle">{{ displayTitle(memo.newMemo) }}</p>
        <span class="memo-date">作成日 {{ convertFromTimestampToDatetime(memo.createdAt.seconds) }}</span>
      </div>
    </div>
    <div class="memo-area">
      <div class="button-area">
        <button class="blue btn" @click="addMemo">メモの追加</button>
        <button class="green btn" @click="saveMemos">メモの保存</button>
        <button class="pink btn" v-if="memos.length > 1" @click="deleteMemo">選択中のメモの削除</button>
      </div>
      <textarea name="" id="" v-model="memos[selectedIndex].newMemo"></textarea>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
const db = firebase.firestore();
const year = new Date().getFullYear();
const month = new Date().getMonth() + 1;
const date = new Date().getDate();

export default {
  name: "Memo",
  data() {
    return {
      id: this.$store.state.userData.id,
      memos: [
        {
          newMemo: "",
          createdAt: new Date(),
        },
      ],
      selectedIndex: 0,
      yearMonth: `${year}/${month}`,
      yearMonthJa: `${year}年${month}月`,
      today: `${year}/${month}/${date}`,
      todayJa: `${year}年${month}月${date}日`,
    };
  },
  async created() {
    await db
      .collection("memos")
      .doc(this.id)
      // .collection(this.yearMonthJa)
      .collection("memos")
      .doc(this.id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.memos = doc.data().memo;
          // console.log(doc.data());
        } else {
          // console.log("No such document")
        }
      });
    // await db.collection("momos").doc(this.id).collection()
  },
  mounted() {
    document.onkeydown = (e) => {
      if (e.key == "s" && (e.metaKey || e.ctrlKey)) {
        this.saveMemos();
        return false;
      }
    };
  },
  methods: {
    async saveMemos() {
      await db
        .collection("memos")
        .doc(this.id)
        // .collection(this.yearMonthJa)
        .collection("memos")
        .doc(this.id)
        .set({
          memo: this.memos,
          date: this.yearMonthJa,
          createdAt: new Date(),
        });
      alert("メモを保存しました。");
    },
    addMemo() {
      this.memos.unshift({
        newMemo: "",
        createdAt: new Date(),
      });
      this.selectedIndex = 0;
      // this.selectedIndex = this.memos.length - 1;
    },
    deleteMemo() {
      this.memos.splice(this.selectedIndex, 1);
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
      }
    },
    selectMemo(index) {
      this.selectedIndex = index;
    },
    displayTitle(text) {
      return text.split(/\n/)[0].replace(/#\s/, "");
    },
    convertFromTimestampToDatetime(timestamp) {
      const _d = timestamp ? new Date(timestamp * 1000) : new Date();
      const Y = _d.getFullYear();
      const m = (_d.getMonth() + 1).toString().padStart(2, "0");
      const d = _d.getDate().toString().padStart(2, "0");
      const H = _d.getHours().toString().padStart(2, "0");
      const i = _d.getMinutes().toString().padStart(2, "0");
      const s = _d.getSeconds().toString().padStart(2, "0");
      return `${m}/${d} ${H}:${i}`;
    },
    // preview() {
    //   return marked(this.memos[this.selectedIndex].marked);
    // },
  },
};
</script>

<style scoped>
.memo-container {
  width: 100%;
  height: calc(100vh - 60px - 39px);
  /* ヘッダーの高さ - タブの高さ */
  display: flex;
  /* background-color: #fbbb; */
}
.monthly-list {
  width: 12%;
  background-color: #fafafa;
}
.date-list {
  width: 23%;
  overflow-y: auto;
  /* background-color: cadetblue; */
}
.date-list::-webkit-scrollbar {
  display: none;
}
.memo-area {
  width: 65%;
}
textarea {
  width: 100%;
  height: 95%;
  border: none;
  resize: none;
  padding: 1rem 0.5rem;
}
.memoList {
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  border-bottom: 1px solid #bbb;
}
.memoList:nth-child(even) {
  background-color: #fafafa;
}
.memoList[data-selected="true"] {
  background-color: #3c4b64;
  color: white;
}
.memoTitle {
  height: 1.5em;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
}

.button-area {
  height: 5%;
  text-align: center;
  display: flex;
  /* justify-content: space-evenly; */
}
.btn {
  margin-left: 1rem;
}
.memo-date {
  font-size: 0.8em;
}
</style>
