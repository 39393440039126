<template>
  <div class="questions">
    <div class="title">食習慣・生活習慣</div>
    <div v-for="n in 24" :key="n">
      <div>{{ Number(n) }}.{{ questions[n - 1].text }}</div>
      <template v-if="questionAnswers[n - 1]">
        <div v-for="m in questionAnswers[n - 1].length" :key="m" class="answer">
          {{ questionAnswers[n - 1][m - 1] }}
        </div>
      </template>
      <br />
    </div>
  </div>
</template>

<script>
import questions from "./questions";
import firebase from "firebase";
const db = firebase.firestore();

export default {
  name: "EatingHabit",
  components: {},
  data() {
    return {
      questions: questions,
      questionAnswers: [],
      habit: null,
      habits: [],
    };
  },
  methods: {},
  created() {
    db.collection("users")
      .doc(this.$store.state.userData.id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.questionAnswers = doc.data().questionAnswer;
        } else {
          // doc.data() will be undefined in this case
          // console.log("No such document!");
        }
      })
      .catch((error) => {
        // console.log("Error getting document:", error);
      });
  },
};
</script>
<style scoped>
.questions {
  padding: 0.5rem 1rem;
}
.answer {
  font-weight: bold;
  color: blue;
}

.chart {
  height: calc((100vh - 60px - 39px) / 2);
}
.title {
  font-weight: bold;
  font-size: 1.2rem;
}
</style>
