<template>
  <div class="about-type-container">
    <div class="wrapper">
      <div>
        <h2>指導者のタイプ別関わり方のポイント</h2>
        <p>
          指導者のタイプにより関わり方の傾向が変わります。
          ご自身のタイプを確認し、お客様のタイプに合わせたアドバイスを心がけましょう。
        </p>
        <p>
          各タイプの特徴をまとめていますので、ご自身がどのタイプなのか確認しましょう。<br />
          ご自身のタイプを正確に把握したい場合、行動特性診断を有料で行っています。こちらからお申し込み・お問い合わせください。
          お申し込みフォームは
          <a target="_blank" href="https://docs.google.com/forms/d/1hnDRllh9Y8ljoOvPSAUllWsH87HxBPIJCrRr0IH6Stk/edit"
            >こちら</a
          >
        </p>
      </div>
      <CTabs>
        <CTab title="ディレクション型"><DirectionType /></CTab>
        <CTab title="ロジカル型"><LogicalType /></CTab>
        <CTab title="アレンジ型"><ArrangeType /></CTab>
        <CTab title="バランス型"><BalanceType /></CTab>
      </CTabs>
    </div>
  </div>
</template>

<script>
import ArrangeType from "@/views/components/TypeDescription/MyTypeDescription/ArrangeType";
import BalanceType from "@/views/components/TypeDescription/MyTypeDescription/BalanceType";
import DirectionType from "@/views/components/TypeDescription/MyTypeDescription/DirectionType";
import LogicalType from "@/views/components/TypeDescription/MyTypeDescription/LogicalType";

export default {
  name: "UserTypeDescription",
  components: {
    ArrangeType,
    BalanceType,
    DirectionType,
    LogicalType,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.about-type-container {
  overflow-y: auto;
}
.wrapper {
  padding: 0 0.5rem;
}
</style>
