const convertFrom8digitBirthdayToDate = (birthday) => {
  // 生年月日を日付型に変換
  const year = Number(birthday.toString().substr(0, 4));
  const month = Number(birthday.toString().substr(4, 2));
  const day = Number(birthday.toString().substr(6, 2));

  return `${year}年${month}月${day}日`;
};

export default convertFrom8digitBirthdayToDate;
