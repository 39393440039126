<template>
  <div>
    <CRow>
      <CCol class="client-create">
        <CCard class="">
          <CCardBody class="">
            <h1>計測値</h1>
            <div class="measureed-value-modal-container">
              <div class="">
                <div class="input-box">
                  <div class="small-input">
                    <div>体重</div>
                    <input v-model="measuredValue.weight" class="input" type="number" step="0.1" min="0" />kg
                  </div>
                </div>
                <div class="input-box">
                  <div class="small-input">
                    <div>体脂肪率</div>
                    <input v-model="measuredValue.bodyFatPercentage" class="input" type="number" step="0.1" min="0" />%
                  </div>
                </div>
                <div class="input-box">
                  <div class="small-input">
                    <div>皮下脂肪率</div>
                    <input v-model="measuredValue.bodyFatWeight" class="input" type="number" step="0.1" min="0" />％
                  </div>
                </div>
                <div class="input-box">
                  <div class="small-input">
                    <div>筋肉率</div>
                    <input v-model="measuredValue.musclePercentage" class="input" type="number" step="0.1" min="0" />％
                  </div>
                </div>
                <div class="input-box">
                  <div class="small-input">
                    <div>体年齢</div>
                    <input v-model="measuredValue.bodyAge" class="input" type="number" step="0.1" min="0" />歳
                  </div>
                </div>
                <div class="input-box">
                  <div class="small-input">
                    <div>基礎代謝</div>
                    <input v-model="measuredValue.basalMetabolism" class="input" type="number" step="0.1" min="0" />kcal
                  </div>
                </div>
                <div class="input-box">
                  <div class="small-input">
                    <div>内臓脂肪</div>
                    <input
                      v-model="measuredValue.visceralFatLevel"
                      class="input"
                      type="number"
                      step="0.1"
                      min="0"
                    />レベル
                  </div>
                </div>
              </div>

              <div class="">
                <div class="input-box">
                  <div class="small-input">
                    <div>二の腕</div>
                    <input v-model="upperArm" class="input" type="number" step="0.1" min="0" />cm
                  </div>
                </div>
                <div class="input-box">
                  <div class="small-input">
                    <div>ウエスト</div>
                    <input v-model="west" class="input" type="number" step="0.1" min="0" />cm
                  </div>
                </div>
                <div class="input-box">
                  <div class="small-input">
                    <div>ヒップ</div>
                    <input v-model="hip" class="input" type="number" step="0.1" min="0" />cm
                  </div>
                </div>
                <div class="input-box">
                  <div class="small-input">
                    <div>大腿</div>
                    <input v-model="femur" class="input" type="number" step="0.1" min="0" />cm
                  </div>
                </div>
                <div class="input-box">
                  <div class="small-input">
                    <div>下腿</div>
                    <input v-model="lowerLeg" class="input" type="number" step="0.1" min="0" />cm
                  </div>
                </div>
              </div>
            </div>
            <button class="add-button btn green" @click="addClient">登録</button>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import firebase from "firebase";
const db = firebase.firestore();
const month = new Date().getMonth() + 1;
const date = new Date().getDate();

export default {
  name: "MeasuredValueModal",
  data() {
    return {
      id: this.$store.state.userData.id,
      measuredValue: {
        weight: null,
        bodyFatPercentage: null,
        bodyFatWeight: null,
        musclePercentage: null,
        bodyAge: null,
        basalMetabolism: null,
        visceralFatLevel: null,
        date: `${month}/${date}`,
      },
      upperArm: null,
      west: null,
      hip: null,
      femur: null,
      lowerLeg: null,
      date: `${month}/${date}`,
    };
  },
  computed: {},
  methods: {
    async addClient() {
      if (
        this.measuredValue.weight ||
        this.measuredValue.bodyFatPercentage ||
        this.measuredValue.bodyFatWeight ||
        this.measuredValue.musclePercentage ||
        this.measuredValue.bodyAge ||
        this.measuredValue.basalMetabolism ||
        this.measuredValue.visceralFatLevel
      ) {
        await db.collection("users").doc(this.id).collection("measuredValue").add({
          measuredValue: this.measuredValue,
          createdAt: new Date(),
        });
        this.$store.commit("pushMeasuredValue", this.measuredValue);
      }
      if (this.upperArm || this.west || this.hip || this.femur || this.lowerLeg) {
        await db
          .collection("users")
          .doc(this.id)
          .collection("subMeasuredValue")
          .add({
            upperArm: this.upperArm,
            west: this.west,
            hip: this.hip,
            femur: this.femur,
            lowerLeg: this.lowerLeg,
            date: `${month}/${date}`,
            createdAt: new Date(),
          });
      }
      this.$store.commit("closeRecordModal");
      this.measuredValue.weight = null;
      this.measuredValue.bodyFatPercentage = null;
      this.measuredValue.bodyFatWeight = null;
      this.measuredValue.musclePercentage = null;
      this.measuredValue.bodyAge = null;
      this.measuredValue.basalMetabolism = null;
      this.measuredValue.visceralFatLevel = null;
      this.upperArm = null;
      this.west = null;
      this.hip = null;
      this.femur = null;
      this.lowerLeg = null;
    },
  },
};
</script>
<style scoped>
.name {
  display: flex;
}

input {
  border: solid 1px #d8dbe0;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  color: #768192;
  margin-right: 0.5rem;
}

.small-input {
  margin-right: 1rem;
}

.input-box {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: flex-end;
}
.measureed-value-modal-container {
  display: flex;
}
.card-body {
  display: flex;
  flex-direction: column;
}
</style>
