<template>
  <div class="table-container">
    <div class="unit">単位(cm)</div>
    <table class="table">
      <thead>
        <tr>
          <th>日付</th>
          <th>二の腕</th>
          <th>ウエスト</th>
          <th>ヒップ</th>
          <th>大腿</th>
          <th>下腿</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="subMeasuredValue in subMeasuredValues" :key="subMeasuredValue.createdAt">
          <td>{{ subMeasuredValue.date }}</td>
          <td>{{ subMeasuredValue.upperArm }}</td>
          <td>{{ subMeasuredValue.west }}</td>
          <td>{{ subMeasuredValue.hip }}</td>
          <td>{{ subMeasuredValue.femur }}</td>
          <td>{{ subMeasuredValue.lowerLeg }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "SubMeasuredValue",
  components: {},
  data() {
    return {
      id: this.$store.state.userData.id,
      subMeasuredValues: this.$store.state.subMeasuredValues,
    };
  },
  methods: {},
};
</script>

<style scoped>
.unit {
  text-align: right;
  font-size: 0.6rem;
}
.table {
  width: 100%;
  height: 100%;
}
.table th {
  padding: 0 0.75rem;
}
.table td {
  padding: 0.1rem 0.75rem;
}
.table tbody tr {
  border: 1px solid #d8dbe0;
}
.table-container {
  overflow-y: auto;
  height: calc((100vh - 60px - 39px - 30px - 1rem) / 3 - 1.6rem);
}
.table-container::-webkit-scrollbar {
  display: none;
}
@media (max-height: 800px) {
  .chart {
    height: calc((100vh - 60px - 39px - 30px - 1rem) / 2 - 1.6rem);
  }
}
</style>
