<template>
  <div class="support-chat-wrapper">
    <div class="support-time-line">
      <div v-for="message in messages" :key="message.createdAt.seconds" @update="URLdetection(message)">
        <div
          class="d-flex"
          :class="[
            message.author._id === authUserEmail ? 'justify-content-end message_wrapper' : 'justify-content-start',
          ]"
        >
          <div :class="[message.author._id === authUserEmail ? 'msg_container_send' : 'msg_container']">
            <div>
              <!-- 単一の画像または配列の画像が1枚のみの場合（画像が存在する場合のみ表示） -->
              <img
                v-if="
                  message.image &&
                  (!Array.isArray(message.image) || (Array.isArray(message.image) && message.image.length === 1))
                "
                class="msg_image"
                :src="Array.isArray(message.image) ? message.image[0] : message.image"
                @click="openImageModal(message.image)"
                alt=""
              />
              <!-- 配列の画像が複数枚の場合 -->
              <div v-if="Array.isArray(message.image) && message.image.length > 1" class="image-grid">
                <img
                  v-for="(img, index) in message.image"
                  :key="index"
                  class="msg_image"
                  :src="img"
                  @click="openImageModal(img)"
                  alt=""
                />
              </div>
            </div>
            <span :class="[message.author._id === authUserEmail ? 'msg_user_name_send' : 'msg_user_name']">{{
              message.author.name
            }}</span>
            <!-- <nl2br tag="div" :text="message.message" /> -->
            <div :id="message.chatId" class="message"></div>
            <span :class="[message.author._id === authUserEmail ? 'msg_time_send' : 'msg_time']">{{
              message.createdAt
            }}</span>
            <span @click="deleteChat(message.chatId)" class="delete-btn">削除</span>
          </div>
        </div>
      </div>
    </div>
    <div class="text-area-container" :style="styles">
      <textarea v-model="message" ref="area" class="message-area"></textarea>
      <div class="upload-file-container" v-if="uploadFileUrl">
        <div class="delete-button" @click="deletePreview">×</div>
        <img class="upload-file" :src="uploadFileUrl" alt="" />
      </div>
      <div class="input-group-append">
        <label class="input-group-text send_btn">
          <span><i class="fas fa-location-arrow"></i></span>
          <input @click="sendMessage" :disabled="processing" class="input_file" />
        </label>
        <label class="input-group-text attach_btn">
          <span><i class="fas fa-paperclip"></i></span>
          <input
            @change="uploadFile"
            :disabled="processing"
            class="input_file"
            type="file"
            name="datafile"
            ref="preview"
          />
        </label>
      </div>
    </div>
    <!-- 画像拡大モーダル -->
    <CModal centered size="lg" :show.sync="showEnlargeModal">
      <img class="image" :src="enlargeModalSrc" alt="" />
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
  </div>
</template>

<script>
import firebase from "firebase";
import Nl2br from "vue-nl2br";
import { v4 as uuidv4 } from "uuid";

const db = firebase.firestore();
const convertFromTimestampToDatetime = (timestamp) => {
  const _d = timestamp ? new Date(timestamp * 1000) : new Date();
  const Y = _d.getFullYear();
  const m = (_d.getMonth() + 1).toString().padStart(2, "0");
  const d = _d.getDate().toString().padStart(2, "0");
  const H = _d.getHours().toString().padStart(2, "0");
  const i = _d.getMinutes().toString().padStart(2, "0");
  const s = _d.getSeconds().toString().padStart(2, "0");
  return `${m}/${d} ${H}:${i}`;
  // return `${Y}/${m}/${d} ${H}:${i}:${s}`;
};
export default {
  name: "SupportChat",
  components: { Nl2br },
  data() {
    return {
      id: this.$store.state.userData.id,
      message: null,
      messages: [],
      height: "",
      // authUser: {}
      authUser: {},
      authUserName: this.$store.state.authUser.displayName,
      authUserPhotoURL: this.$store.state.authUser.photoURL,
      authUserEmail: this.$store.state.authUser.email,
      uploadFileUrl: null,
      uploadFileUrl2: null,
      fileName: "",
      userRole: this.$store.state.clientUserData.clientUserData.role,
      processing: false,
      showEnlargeModal: false,
      enlargeModalSrc: null,
    };
  },
  created() {
    this.fetchMessages();
  },
  computed: {
    styles() {
      return {
        height: this.height,
      };
    },
    clientUserData() {
      return this.$store.state.clientUserData.clientUserData;
    },
    companyName() {
      return this.$store.state.clientUserData.clientData.companyName.name;
    },
    clientUid() {
      return this.$store.state.clientUserData.clientUserData.uid;
    },
  },
  updated() {
    this.$nextTick(() => {
      this.messages.forEach((item) => {
        this.URLdetection(item);
      });
    });
  },
  mounted() {
    this.textAreaResize();
    this.scrollToBottom();
    const chatText = localStorage.getItem(`supportMessage${this.id}`);
    if (chatText) {
      this.message = chatText;
    } else {
      return;
    }
  },
  watch: {
    message(newMessage) {
      this.textAreaResize();
      localStorage.setItem(`supportMessage${this.id}`, newMessage);
      if (!newMessage) {
        localStorage.removeItem(`supportMessage${this.id}`);
      } else {
        return;
      }
    },
  },
  methods: {
    textAreaResize() {
      this.height = this.$refs.area.scrollHeight + "px";
      this.height = "auto";
      this.$nextTick(() => {
        this.height = this.$refs.area.scrollHeight + "px";
      });
    },
    scrollToBottom() {
      let box = document.querySelector(".support-time-line");
      box.scrollTop = box.scrollHeight;
    },
    async sendMessage() {
      if (this.message !== null) {
        this.processing = true;
        if (this.uploadFileUrl) {
          const chatDocRef = await this.$store.dispatch("createChatDocRef");
          const ext = this.fileName.split(".").pop();
          const storagePath = `chats/${chatDocRef.id}.${ext}`;
          const downloadUrl = await this.uploadImage(this.uploadFileUrl2.result, storagePath);
          await db
            .collection("chats")
            .doc(this.id)
            .collection("supportChat")
            .add({
              _id: uuidv4(),
              uid: this.clientUid,
              createdAt: new Date(),
              text: this.message,
              // user: this.authUser.displayName,
              user: {
                _id: this.authUserEmail,
                avatar: this.authUserPhotoURL,
                name: this.authUserName,
                uid: this.clientUserData.uid,
                clientLastName: this.clientUserData.lastName,
                clientFirstName: this.clientUserData.firstName,
                companyName: this.companyName,
              },
              image: downloadUrl,
            });
          this.deletePreview();
        } else {
          // const adminMailData = {
          //   to: ["yotsuhashi.ken@gmail.com"],
          //   message: {
          //     subject: "ホームページお問い合わせ",
          //     text: "This is the plaintext section of the email body.",
          //     html: "This is the <code>HTML</code> section of the email body.",
          //   },
          // };

          // await db.collection("mail").add(adminMailData);
          await db
            .collection("chats")
            .doc(this.id)
            .collection("supportChat")
            .add({
              _id: uuidv4(),
              uid: this.clientUid,
              text: this.message,
              // user: this.authUser.displayName,
              user: {
                _id: this.authUserEmail,
                avatar: this.authUserPhotoURL,
                name: this.authUserName,
                uid: this.clientUserData.uid,
                clientLastName: this.clientUserData.lastName,
                clientFirstName: this.clientUserData.firstName,
                companyName: this.companyName,
              },
              createdAt: new Date(),
            })
            .then(() => {
              this.scrollToBottom();
            });
          if (this.userRole == "eatas") {
            db.collection("users").doc(this.id).set(
              {
                support: "新着",
              },
              { merge: true }
            );
          } else {
            db.collection("users").doc(this.id).set(
              {
                support: "問合せ中",
              },
              { merge: true }
            );
          }
        }
        this.message = null;
        this.processing = false;
      } else {
        alert("空文字のため送信できません。\nメッセージを入力してください。");
      }
    },
    async uploadImage(uri, path) {
      // uriをblobに変換
      // console.log(uri);
      const localUri = await fetch(uri);
      const blob = await localUri.blob();
      const ref = firebase.storage().ref().child(path);

      let downloadUrl = "";
      try {
        await ref.put(blob);
        downloadUrl = await ref.getDownloadURL();
      } catch (err) {
        // console.log(err);
      }
      return downloadUrl;
    },
    uploadFile() {
      const file = this.$refs.preview.files[0];
      this.fileName = this.$refs.preview.files[0].name;
      this.uploadFileUrl = URL.createObjectURL(file);
      let reader = new FileReader();
      reader.readAsDataURL(file);
      this.uploadFileUrl2 = reader;

      this.$refs.preview.value = "";
    },
    deletePreview() {
      this.uploadFileUrl = "";
      URL.revokeObjectURL(this.uploadFileUrl);
    },
    fetchMessages() {
      db.collection("chats")
        .doc(this.id)
        .collection("supportChat")
        .orderBy("createdAt", "asc")
        .onSnapshot((querySnapshot) => {
          let allMessages = [];
          querySnapshot.forEach((doc) => {
            // allMessages.push(doc.data());
            allMessages.push({
              message: doc.data().text,
              author: doc.data().user,
              createdAt: convertFromTimestampToDatetime(doc.data().createdAt.seconds),
              image: doc.data().image,
              chatId: doc.id,
            });
          });
          this.messages = allMessages;

          setTimeout(() => {
            this.scrollToBottom();
          }, 1000);
        });
    },
    deleteChat(chatId) {
      if (!confirm("この作業は元に戻せません。メッセージを削除しますか？")) {
        return false;
      } else {
        firebase
          .firestore()
          .collection("chats")
          .doc(this.id)
          .collection("supportChat")
          .doc(chatId)
          .delete()
          .then(function () {
            // console.log("Document successfully deleted!");
          })
          .catch(function (error) {
            console.error("Error removing document: ", error);
          });
      }
    },
    openImageModal(src) {
      this.enlargeModalSrc = src;
      this.showEnlargeModal = true;
    },
    URLdetection(message) {
      // 正規表現を使って、メッセージ中のURLを抽出
      const urlRegex = /https?:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+/g;
      const text = message.message.replace(urlRegex, function (url) {
        // リンクのHTMLタグを生成して、メッセージ中のURLをリンク化。
        return '<a href="' + url + '" target="_blank">' + url + "</a>";
      });
      const id = document.getElementById(message.chatId);
      id.innerHTML = text;
    },
  },
};
</script>

<style scoped>
label {
  margin: 0;
}
.support-chat-wrapper {
  height: calc(100vh - 60px - 39px);
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
}
.support-time-line {
  height: 80%;
  overflow-y: auto;
  padding: 2rem 0.5rem 0 0.5rem;
}
.support-time-line::-webkit-scrollbar {
  display: none;
}
.msg_container {
  text-align: left;
  margin-top: auto;
  margin-bottom: 2rem;
  margin-left: 10px;
  border-radius: 12px;
  background-color: #bbb;
  color: #fff;
  padding: 5px 5px 0px 5px;
  position: relative;
  max-width: 70%;
}
.msg_container_send {
  text-align: left;
  margin-top: auto;
  margin-bottom: 2rem;
  margin-right: 10px;
  border-radius: 12px;
  background-color: #f1f1f1;
  padding: 5px 10px;
  position: relative;
  max-width: 70%;
}
.message {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 10px;
}
.msg_time {
  position: absolute;
  right: -60px;
  bottom: 0;
  color: black;
  font-size: 10px;
}
.msg_time_send {
  position: absolute;
  left: -60px;
  bottom: 0;
  color: black;
  font-size: 10px;
}
.msg_user_name {
  position: absolute;
  left: 0;
  top: -15px;
  color: black;
  font-size: 10px;
  text-align: left;
  width: 300px;
}
.msg_user_name_send {
  position: absolute;
  right: 0;
  top: -15px;
  color: black;
  font-size: 10px;
  text-align: right;
  width: 300px;
}
.delete-btn {
  display: none;
}
.message_wrapper:hover .delete-btn {
  display: block;
  position: absolute;
  left: -30px;
  top: 0;
  color: rgb(126, 126, 126);
  font-size: 10px;
  text-align: left;
  cursor: pointer;
}
.msg_image {
  width: 100%;
  border-radius: 25px;
}
.message-area {
  height: 100%;
  width: 100%;
  background-color: #eee;
  resize: none;
  border: none;
  border-radius: 12px;
  margin-right: 0.5rem;
  padding: 0.5rem;
  /* z-index: 100; */
  /* display: none; */
}
.text-area-container {
  min-height: 20%;
  max-height: 50%;
  display: flex;
  padding: 0.5rem;
}
.input-group-append {
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}
.input-group-text {
  height: 50px;
  width: 100%;
  background-color: #bbb;
  color: #fff;
}
.input_file {
  display: none;
}
.upload-file-container {
  display: flex;
  align-items: flex-end;
  position: relative;
  margin: 0 0.5rem;
}
.upload-file {
  height: 80px;
  width: 80px;
}
.delete-button {
  position: absolute;
  text-align: center;
  font-weight: bold;
  width: 20px;
  height: 20px;
  bottom: 75px;
  right: 0;
  line-height: 18px;
  background-color: #000;
  color: #eee;
  border-radius: 20px;
  cursor: pointer;
}
.image {
  height: 80vh;
  width: 100%;
  object-fit: contain;
}
.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3px;
}
</style>
